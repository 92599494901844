import { jwtHelper } from '../helpers'
import { authService } from '../services'
import Vue from 'vue'
import VueRouter from 'vue-router'
import { store } from './../store'
import LoginLayout from '../layouts/Login.vue'
import MainLayout from '../layouts/Main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import(/* webpackChunkName: "results" */ '../views/Results/Home.vue')
      }
    ]
  },
  {
    path: '/login',
    component: LoginLayout,
    children: [
      {
        path: '',
        name: 'Login',
        component: () => import(/* webpackChunkName: "auth" */ '../views/Auth/Login.vue')
      }
    ]
  }
]

export const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior () {
    return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)

  const publicPages = ['/forgot', '/login', '/register', '/p']
  let authRequired = !publicPages.includes(to.path)
  if (authRequired) {
    authRequired = to.path.indexOf('/l/') === -1 &&
                   to.path.indexOf('/p/') === -1 &&
                   to.path.indexOf('/r/') === -1 &&
                   to.path.indexOf('/register/') === -1
  }
  const loggedIn = jwtHelper.isStoredValid()

  if (!loggedIn) {
    if (jwtHelper.canRefresh()) {
      authService.refresh()
      if (!jwtHelper.isStoredValid()) {
        store.dispatch('auth/logout')
        if (authRequired) {
          return next('/login')
        }
      }
    } else {
      store.dispatch('auth/logout')
      if (authRequired) {
        return next('/login')
      }
    }
  }

  next()
})
