<template>
  <div id="MainLayout" class="row-offcanvas row-offcanvas-left">
    <nav class="sidebar-offcanvas sidebar">
      <a class="navbar-brand" href="#"><img :src="require('@/assets/images/logo-icon.svg')" class="m-auto d-block img-fluid" /></a>
      <b-nav class="flex-column align-self-end">
        <b-nav-item class="mx-auto" active>
          <div class="pt-1"><font-awesome-icon icon="lungs" size="2x" /></div>
        </b-nav-item>
      </b-nav>
      <b-nav class="flex-column bottom">
        <b-nav-item class="mx-auto" @click="changePassword()">
          <div class="pt-2">
            <font-awesome-icon icon="user-lock" size="2x" />
            <span class="text">Change Password</span>
          </div>
        </b-nav-item>
        <b-nav-item class="mx-auto" @click="doLogout()">
          <div class="pt-2">
            <font-awesome-icon icon="sign-out-alt" size="2x" />
            <span class="text">Logout</span>
          </div>
        </b-nav-item>
      </b-nav>
    </nav>
    <main role="main" class="main">
      <router-view/>
    </main>
    <!-- Confirmation modal -->
    <b-modal id="change_password" size="lg" centered hide-header hide-footer>
      <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('change_password')">×</button>
      <h4 class="mb-4">Change Password</h4>
      <b-form @submit.prevent="submit">
        <b-form-group id="password-group" label-for="password">
          <b-form-input
            v-model="passwords.current"
            id="password"
            type="password"
            required
            placeholder="Please enter your current password"
          ></b-form-input>
        </b-form-group>
        <hr />
        <b-form-group id="new-group" label-for="new">
          <b-form-input
            v-model="passwords.new"
            id="new"
            type="password"
            required
            placeholder="Please enter your new password"
          ></b-form-input>
        </b-form-group>
        <b-form-group id="repeat-group" label-for="repeat">
          <b-form-input
            v-model="passwords.repeat"
            class="mb-1"
            id="repeat"
            type="password"
            required
            placeholder="Please repeat your new password"
          ></b-form-input>
        </b-form-group>
        <div v-if="passwords.new !== passwords.repeat && passwords.repeat" class="alert alert-danger">Passwords do not match.</div>
        <div v-if="passwords.current === passwords.new && passwords.new" class="alert alert-danger">Current and new passwords cannot be the same.</div>
        <b-button :disabled="formStatus === 'submitting' || !canSubmit" type="submit" pill class="btn-yellow px-5">Proceed</b-button>
      </b-form>
    </b-modal>
    <!-- End modal -->
    <div class="version version-main">
      (01)5065012568004(8012)<b>v3.8.0</b>
    </div>
    <div class="copyright">
      &copy; Copyright Envisionit Deep AI&copy;. All rights reserved.
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      formStatus: null,
      passwords: {
        current: null,
        new: null,
        repeat: null
      }
    }
  },
  computed: {
    ...mapState({
      auth: state => state.auth
    }),
    changeStatus () {
      return this.auth.status.changed
    },
    canSubmit () {
      if (this.passwords.current) {
        if (this.passwords.new) {
          if (this.passwords.current !== this.passwords.new) {
            if (this.passwords.repeat) {
              if (this.passwords.new === this.passwords.repeat) {
                return true
              } else {
                return false
              }
            } else {
              return false
            }
          } else {
            return false
          }
        } else {
          return false
        }
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('auth', ['logout', 'change']),
    doLogout: function () {
      this.logout()
      this.$router.push('/login')
    },
    changePassword: function () {
      this.$bvModal.show('change_password')
    },
    submit: function () {
      this.formStatus = 'submitting'
      this.change(this.passwords)
    }
  },
  watch: {
    changeStatus (value) {
      if (value === true) {
        this.$bvModal.hide('change_password')
      }
    },
    'passwords.current' () {
      this.formStatus = null
    },
    'passwords.new' () {
      this.formStatus = null
    },
    'passwords.repeat' () {
      this.formStatus = null
    }
  }
}
</script>
