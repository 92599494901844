const state = {
  options: [
    'Atelectasis',
    'Bone Lesion',
    'Bronchiectasis',
    'Bullae',
    'Calcified Diaphragm',
    'Calcification Egg Shell',
    'Calcified Nodules',
    'Cardiomegaly',
    'Cavity',
    'Consolidation',
    'CP Angle Obliteration',
    'Cysts',
    'Elevated Diaphragm',
    'Emphysema',
    'Fibrosis',
    'Ground Glass',
    'Hyperinflation',
    'Infiltrates',
    'Interstitial Infiltrates',
    'Irregular Opacities',
    'Lymphadenopathy',
    'Micronodules',
    'Mass',
    'Nodules',
    'Other',
    'Pleural Effusion',
    'Pleural Plaques',
    'Pleural Thickening',
    'Pneumothorax',
    'Pneumomediastinum',
    'Peribronchial Thickening',
    'Post Surgical Hardware',
    'Pulmonary Arteries Enlarged',
    'Reticular Infiltrates',
    'Unfolded Aorta'
  ]
}

export const pathologies = {
  namespaced: true,
  state
}
