import Vue from 'vue'
import Vuex from 'vuex'

import { alert } from './alert.module'
import { auth } from './auth.module'

import { pathologies } from './pathology.module'
import { staffReport } from './staff.report.module'
import { staffSubmission } from './staff.submission.module'

Vue.use(Vuex)

export const store = new Vuex.Store({
  modules: {
    alert,
    auth,
    pathologies,
    staffReport,
    staffSubmission
  }
})
