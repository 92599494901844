import 'es6-promise/auto'
import 'whatwg-fetch'
import Vue from 'vue'
import App from './App.vue'
import { router } from './helpers'
import { store } from './store'

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import VueUploadComponent from 'vue-upload-component'
import '@/assets/css/custom.scss'
import '@/assets/css/extras.scss'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome, faLungs, faSignOutAlt, faSearch, faCog, faBell, faExclamationTriangle, faHeartbeat, faExpandArrowsAlt, faCheckCircle, faTimesCircle, faPlus, faPoll, faTrash, faPen, faUpload, faDownload, faUndo, faRedo, faCheck, faTimes, faDna, faMicrochip, faEye, faEyeSlash, faUserLock, faCheckSquare, faSquare, faCommentDots, faUserInjured, faAdjust, faCommentMedical, faMicrophoneAlt, faFileAlt, faCalendar, faSortAmountUp, faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faHome, faLungs, faSignOutAlt, faSearch, faCog, faBell, faExclamationTriangle, faHeartbeat, faExpandArrowsAlt, faCheckCircle, faTimesCircle, faPlus, faPoll, faTrash, faPen, faUpload, faDownload, faUndo, faRedo, faCheck, faTimes, faDna, faMicrochip, faEye, faEyeSlash, faUserLock, faCheckSquare, faSquare, faCommentDots, faUserInjured, faAdjust, faCommentMedical, faMicrophoneAlt, faFileAlt, faCalendar, faSortAmountUp, faEdit)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueLodash, { lodash: lodash })

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.component('file-upload', VueUploadComponent)

Vue.config.productionTip = false

const numeral = require('numeral')

Vue.filter('formatInt', function (number) {
  if (!number) return number
  try {
    const _number = parseInt(number)
    return numeral(_number).format('0,0')
  } catch (error) {
    return number
  }
})

Vue.filter('formatSize', function (bytes) {
  if (!bytes) return 'Unknown'
  const decimals = 2
  const k = 1024
  const dm = decimals < 0 ? 0 : decimals
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i]
})

Vue.filter('formatPathologyName', function (pathology, options) {
  const _updated = options.find(_pathology => {
    const __pathology = _pathology.replace(/[^a-zA-Z]/g, '').toLowerCase()
    return __pathology === pathology.toLowerCase()
  })
  if (_updated) {
    return _updated
  } else {
    return pathology
  }
})

if (Array.prototype.equals) {
  console.warn('Overriding existing Array.prototype.equals. Possible causes: New API defines the method, there\'s a framework conflict or you\'ve got double inclusions in your code.')
}

// eslint-disable-next-line no-extend-native
Array.prototype.equals = function (array) {
  if (!array) {
    return false
  }

  if (this.length !== array.length) {
    return false
  }

  for (var i = 0, l = this.length; i < l; i++) {
    if (this[i] instanceof Array && array[i] instanceof Array) {
      if (!this[i].equals(array[i])) {
        return false
      }
    } else if (this[i] !== array[i]) {
      return false
    }
  }

  return true
}

// eslint-disable-next-line no-extend-native
Object.defineProperty(Array.prototype, 'equals', { enumerable: false })

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
